<template>
    <div style="display:block;margin-left:auto; margin-right:auto">

        <div >

                   <div  style="padding-top:5px; padding-left:17px;padding-right: 17px">

                        <h2 class="qalignmiddle qmajorheader">{{ langConv('Exchange Settings') }}</h2>
                        <div class="qsubheader qalignmiddle qsmallfontplus">{{ langConv('Exchanges earn their keep by taking a small cut from your winnings, not by adding a margin to the odds.') }}</div>
                        <div class="qsubheader qalignmiddle qsmallfontplus">{{ langConv('Set the % that will be deducted from your winnings by the exchange') }}</div>
                        <div class="qsubheader qalignmiddle qsmallfontplus">{{ langConv('This will then be calculated into the odds shown (for the exchange) on this site') }}</div>
            
                   </div>
        </div>

               
                  <div class="grid-x grid-padding-x size-up-small-6 " style="text-align:center;padding:30px">

                        <div class="cell small-6"><span class="bBetfair"></span></div><div class="cell shrink "><input style="font-size:12px" type="number" v-model="$store.state.betfaircom" v-on:change="commitVal($event.target.value,'setBetfaircom')" min="0" max="10" /></div><div class="cell shrink percent">%</div>
                        <div class="cell small-6"><span class="bBetDaq"></span></div><div class="cell shrink"><input style="font-size:12px" type="number" v-model="$store.state.betdaqcom" v-on:change="commitVal($event.target.value,'setBetdaqcom')" min="0" max="10" /></div><div class="cell shrink percent">%</div>
                        <div class="cell small-6"><span class="bMatchbook"></span></div><div class="cell shrink"><input style="font-size:12px" type="number" v-model="$store.state.smarketcom" v-on:change="commitVal($event.target.value,'setSmarketcom')" min="0" max="10" /></div><div class="cell shrink percent">%</div>
                    </div>
    </div>
</template>

<script>
    export default {

        methods: {
            commitVal(val, mutation) {
            
                this.$store.commit(mutation, val)
            }
        }
    }
</script>

<style scoped>

.percent {
    padding:5px;
}

</style>